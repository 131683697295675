import Cookies from "js-cookie";

// This function fetches quota data from the server and checks if the quota banner should be shown
export const fetchQuotaData = async () => {
  try {
    const response = await fetch("/paddle/");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const quotaUsageInfo = data[0]?.subscription_plan?.total_quota_usage || 0;
    const userBalanceInfo = data[0]?.user_balance_info;
    const subscriptionInfo = data[0]?.subscription_info;
    const subscriptionPlan = data[0]?.subscription_plan;

    const quotaHide = Cookies.get("quotaHide");

    // Return the quota usage info and hide state
    return {
      quotaUsageInfo,
      quotaHide,
      userBalanceInfo,
      subscriptionPlan,
      subscriptionInfo,
    };
  } catch (error) {
    console.log(error);
    throw error; // In case the caller wants to handle the error
  }
};
