import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SubscriptionSuccess from "../components/SubscriptionSuccess";

const SubscriptionSuccessPage = ({
  staticBasePath,
  djangoContext,
}) => {
  return (
    <>
      <Header staticBasePath={staticBasePath} djangoContext={djangoContext} />
      <div className="rb-main-content pricing-page">
        <div className="rb-container">
          <SubscriptionSuccess
            staticBasePath={staticBasePath}
          />
        </div>
      </div>
      <Footer staticBasePath={staticBasePath} />
    </>
  );
};
export default SubscriptionSuccessPage;
