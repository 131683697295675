import React, { useState, useEffect } from "react";
import moment from "moment";
import Pagination from "./Pagination";
import LendingPricingSvg from "../../svg/LendingPricingSvg";

const BillingHistory = ({ setBillingHistoryShow }) => {
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const formattedDateTime = (date) =>
    moment(date, "YYYY-MM-DD hh:mm:ss").format("MM/DD/YY");

  const handleClickReturnBilling = () => {
    setBillingHistoryShow(false);
  };
  // Initial state for input values
  const [inputValues, setInputValues] = useState({
    transactions: [],
    current_page: 1,
    total_pages: 1,
  });

  // Fetch user data from API

  const paddleData = async (page = 1) => {
    try {
      const response = await fetch(`/paddle/billing-history/?page=${page}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      setInputValues({
        transactions: data?.transactions || [],
        current_page: data?.current_page || 1,
        total_pages: data?.total_pages || 1,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    paddleData(1);
  }, []);

  // Handle pagination
  const handlePageChange = (newPage) => {
    paddleData(newPage); // Call the function with the new page number
  };

  if (isLoading) {
    return (
      <div className="rb-display-flex rb-justify-content-center">
        Loading...
      </div>
    ); // Show a loading message while data is being fetched
  }
  return (
    <div className="user-profile-tabs-content-item billing billing-history">
      <div className="user-profile-line user-profile-line-billing-history rb-display-flex">
        <button onClick={() => handleClickReturnBilling()}>
          Return to Billing
        </button>
      </div>
      <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
        <h2>Transactions</h2>
      </div>
      <div className="user-profile-line user-profile-line-billing-history html-table-wrap">
        {/* List Transactions */}
        {inputValues["transactions"].length > 0 ? (
          <div className="html-table">
            <table>
              <thead>
                <tr>
                  <th>Invoice Number</th>
                  <th>Product Name</th>
                  <th>Billed At</th>
                  <th>Billing Period</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {inputValues["transactions"].map((transaction, index) => (
                  <tr key={index}>
                    <td>
                      {" "}
                      <a
                        href={transaction.download_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {transaction.invoice_number}{" "}
                      </a>
                    </td>
                    <td>{transaction.product_name}</td>
                    <td>{formattedDateTime(transaction.billed_at)}</td>
                    <td>
                      {formattedDateTime(transaction.billing_period.start)} -{" "}
                      {formattedDateTime(transaction.billing_period.end)}
                    </td>
                    <td>{transaction.formatted_amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No transactions available.</p>
        )}
      </div>
      {/* Pagination Controls */}
      {inputValues["total_pages"] > 1 && (
        <Pagination
          currentPage={inputValues["current_page"]}
          totalPages={inputValues["total_pages"]}
          onPageChange={handlePageChange}
        />
      )}
      <div className="billing-history-buttons rb-display-flex rb-align-items-center">
        <button
          onClick={() => handleClickReturnBilling()}
          className="button rb-btn button-dark rb-display-flex rb-align-items-center"
        >
          <LendingPricingSvg />
          Back
        </button>
      </div>
    </div>
  );
};

export default BillingHistory;
