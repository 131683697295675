import React, { useState, useEffect } from "react";
import Pagination from "../user-profile/Pagination";
import ReportBlock from "./ReportBlock";
import { reportsProducerData } from "../../utils/apis";
import LendingPricingSvg from "../../svg/LendingPricingSvg";

const ProducerContent = () => {
  // Initial state for input values
  const [inputValues, setInputValues] = useState({
    strategies: [],
    current_page: 1,
    total_pages: 1,
    producer_identity: {
      name: "",
      verified_by: "",
    },
    press_release: false,
    validation_information: {
      total_strategies: "",
      total_commits: "",
    },
  });

  useEffect(() => {
    reportsProducerData(1, setInputValues);
  }, []);

  // Handle pagination
  const handlePageChange = (newPage) => {
    reportsProducerData(newPage, setInputValues); // Call the function with the new page number
  };

  return (
    <div className="reports reports-producer">
      <div className="reports-text">
        <h1>Strategy Producer Profile</h1>
        <p>
          The vBase app verifiably secures data, creating a timestamped record
          of your data’s existence. Build your credibility. Anyone with whom you
          share your file can validate its timestamp and integrity by checking
          the blockchain fingerprint.
        </p>
      </div>
      <div className="reports-content">
        <div className="reports-head rb-display-flex rb-justify-content-space-between rb-flex-wrap-wrap">
          <div className="reports-head-left">
            <h2>Producer Information</h2>
            <div className="reports-head-left-block">
              <span>Strategy Producer Identity:</span>
              <b>{inputValues.producer_identity?.name}</b>
              {inputValues.producer_identity?.verified_by && (
                <>(Verified by {inputValues.producer_identity?.verified_by})</>
              )}
            </div>
          </div>
          <div className="reports-head-right">
            <div className="reports-blocks rb-display-flex rb-justify-content-space-between">
              {inputValues?.validation_information?.total_strategies && (
                <ReportBlock
                  Background="#EDF0E5"
                  Color="#000000"
                  Text="Total Strategies"
                  Number={inputValues?.validation_information?.total_strategies}
                  position="top-right"
                  text="Total Strategies Total Strategies Total Strategies Total Strategies Total Strategies"
                  link="#"
                />
              )}
              {inputValues?.validation_information?.total_commits && (
                <ReportBlock
                  Background="#000000"
                  Color="#FFFFFF"
                  Text="Total Commits"
                  Number={inputValues?.validation_information?.total_commits}
                  position="top-right"
                  text="Total Commits Total Commits Total Commits Total Commits Total Commits "
                  link="#"
                />
              )}
            </div>
            <div className="reports-all">
              <div className="reports-all-title">
                Press Release:{" "}
                {inputValues?.press_release ? <a href="#">Yes</a> : <>No</>}
              </div>
            </div>
          </div>
        </div>
        <div className="html-table-wrap">
          <div className="reports-table-head rb-display-flex rb-justify-content-space-between">
            <div className="reports-table-head-left">Strategies</div>
          </div>
          {inputValues["strategies"].length > 0 ? (
            <div className="html-table">
              <table>
                <thead>
                  <tr>
                    <th>Strategy Name</th>
                    <th># of Commits</th>
                    <th>First Commit</th>
                    <th>Last Commit</th>
                    <th>Press Release</th>
                  </tr>
                </thead>
                <tbody>
                  {inputValues["strategies"].map((strategy, index) => (
                    <tr key={index}>
                      <td>
                        {strategy.strategy_name && (
                          <a href="#">{strategy.strategy_name}</a>
                        )}
                      </td>
                      <td>{strategy.of_commits}</td>
                      <td>{strategy.first_commit}</td>
                      <td>{strategy.last_commit}</td>
                      <td>
                        {strategy.strategy_name ? <a href="#">Yes</a> : <>No</>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No data available.</p>
          )}
        </div>
        {/* Pagination Controls */}
        {inputValues["total_pages"] > 1 && (
          <Pagination
            currentPage={inputValues["current_page"]}
            totalPages={inputValues["total_pages"]}
            onPageChange={handlePageChange}
          />
        )}
      </div>
      <div className="reports-buttons rb-display-flex rb-align-items-center">
        <a
          href="/reports/"
          className="button rb-btn button-dark rb-display-flex rb-justify-content-center rb-align-items-center"
        >
          <LendingPricingSvg />
          Back
        </a>
      </div>
    </div>
  );
};

export default ProducerContent;
