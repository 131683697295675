import Cookies from "js-cookie";

export const URLs = Object.freeze({
  PADDLE_DETAILS_URL: "/paddle/",
  CONFIG_URL: "/profile/config/",
  REPORTS_URL: "/reports/data/?page=",
  REPORTS_PRODUCER_URL: "/reports/producer/data/?page=",
  USER_INFO_URL: "/users/user-info/",
  USER_INFO_SAVE_PROFILE_URL: "/users/save-profile/",
  USER_INFO_USER_ADDRESS_HISTORY_URL: "/users/user-address-history/",
  USER_PROFILE_DELETE_ACCOUNT_URL: "/profile/new/delete_account/",
  PROFILE_API_KEYS_URL: "/profile/new/api_keys/",
  PROFILE_STATISTICS_URL: "/profile/new/statistics/",
  PROFILE_PAYMENT_URL: "/profile/payment/",
  VERIFY_URL: "/verify/",
  PADDLE_CANCEL_URL: "/paddle/cancel/",
  CONTACTS_URL: "https://www.vbase.com/contact/",
  SUBSCRIPTION_SUCCESS_URL: "/paddle/subscription-success/",
  SUBSCRIPTION_FAILED_URL: "/paddle/subscription_failed/",
  USER_FOUND_DATA_URL: "/profile/user-found-data/",
});

export const userFoundData = async (newPage, setInputValues, uidParam) => {
  try {
    const response = await fetch(URLs.USER_FOUND_DATA_URL + "?uid=" + uidParam);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const dataList = await response.json();
    const data = dataList[0];
    if (data.static_data) {
      setInputValues({
        user_found: true,
        stamp_history: data?.stamp_history || [],
        current_page: data?.current_page || 1,
        total_pages: data?.total_pages || 1,
        persistent_ID: data?.persistent_ID || "",
        current_display_name: data?.current_display_name || "",
        current_user_ID: data?.current_user_ID || "",
        current_account_status: data?.current_account_status || "Not Verified",
        total_stamps: data?.total_stamps || "",
        sets: data?.sets || "",
        download_csv: data?.download_csv || "",
      });
    } else {
      setInputValues({
        user_found: false,
      });
    }
    setIsLoading(false); // Set loading to false when data is fetched
  } catch (error) {
    console.log(error);
    setIsLoading(false); // Handle errors by stopping the loading state
  }
};

export const paddleDataSuccess = async (setInputValues, setIsLoading) => {
  try {
    const response = await fetch(URLs.PADDLE_DETAILS_URL);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const dataList = await response.json();
    const data = dataList[0];
    setInputValues({
      subscription_info: {
        subscription_paddle_id:
          data.subscription_info?.subscription_paddle_id || false,
        last_transaction_paddle_id:
          data.subscription_info.last_transaction_paddle_id || false,
      },
      user_balance_info: {
        expirable_credits: data.user_balance_info.expirable_credits || 0,
        paid_credits: data.user_balance_info.paid_credits || 0,
        total_credits: data.user_balance_info.total_credits || 0,
      },
      subscription_plan: {
        title: data.subscription_plan?.title || false,
      },
      billing_info: {
        email: data.billing_info?.email || "",
      },
    });
    setIsLoading(false); // Set loading to false when data is fetched
  } catch (error) {
    console.log(error);
    setIsLoading(false); // Handle errors by stopping the loading state
  }
};

export const fetchStatisticsData = async (setInputValues) => {
  try {
    const response = await fetch(URLs.PROFILE_STATISTICS_URL);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    setInputValues({
      last_login: data.last_login || "",
      user_joined: data.user_joined || "",
    });
  } catch (error) {
    console.log(error);
  }
};

export const reportsData = async (page = 1, setInputValues) => {
  try {
    const response = await fetch(URLs.REPORTS_URL + page);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    setInputValues({
      allocations: data?.allocations || [],
      current_page: data?.current_page || 1,
      total_pages: data?.total_pages || 1,
      producer_identity: {
        name: data?.static_data?.producer_identity?.name || "",
        url: data?.static_data?.producer_identity?.url || "",
      },
      strategy_name: data?.static_data?.strategy_name || "",
      press_release_pdf: data?.static_data?.press_release_pdf || "",
      validation_information: {
        strategy_commits:
          data?.static_data?.validation_information?.strategy_commits || 0,
        other_strategies_commits:
          data?.static_data?.validation_information?.other_strategies_commits ||
          0,
        total_commits:
          data?.static_data?.validation_information?.total_commits || 0,
      },
      available_for_download:
        data?.static_data?.available_for_download || false,
      download_ZIP: data?.static_data?.download_ZIP || "",
      download_single_CSV: data?.static_data?.download_single_CSV || "",
    });
  } catch (error) {
    console.log(error);
  }
};
export const reportsProducerData = async (page = 1, setInputValues) => {
  try {
    const response = await fetch(URLs.REPORTS_PRODUCER_URL + page);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    setInputValues({
      strategies: data?.strategies || [],
      current_page: data?.current_page || 1,
      total_pages: data?.total_pages || 1,
      producer_identity: {
        name: data?.static_data?.producer_identity?.name || "",
        verified_by: data?.static_data?.producer_identity?.verified_by || "",
      },
      press_release: data?.static_data?.press_release || "",
      validation_information: {
        total_strategies:
          data?.static_data?.validation_information?.total_strategies || 0,
        total_commits:
          data?.static_data?.validation_information?.total_commits || 0,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const fetchConfigData = async () => {
  try {
    const response = await fetch(URLs.CONFIG_URL);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const { PADDLE_ENVIRONMENT, PADDLE_CLIENT_SIDE_TOKEN } = data;
    return { PADDLE_ENVIRONMENT, PADDLE_CLIENT_SIDE_TOKEN };
  } catch (error) {
    console.log(error);
    throw error; // In case the caller wants to handle the error
  }
};

export const fetchQuotaData = async () => {
  try {
    const response = await fetch(URLs.PADDLE_DETAILS_URL);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const quotaUsageInfo = data[0].quota_usage_info || 0;
    const quotaHide = Cookies.get("quotaHide");

    // Return the quota usage info and hide state
    return { quotaUsageInfo, quotaHide };
  } catch (error) {
    console.log(error);
    throw error; // In case the caller wants to handle the error
  }
};
