import React, { useState, useEffect } from "react";
import {
  handleButtonClickUserProfile,
  handleInputChangeUserProfile,
  handleButtonClickUserAddressHistory,
} from "../../utils/userProfile";
import { URLs } from "../../utils/apis";
const UserInfo = ({
  setUserAddressHistoryData,
  setUserDelete,
  setChangePassword,
}) => {
  // Temporarily hide items in development
  const [hideInProgress, setHideInProgress] = useState(true);

  const [isLoading, setIsLoading] = useState(true); // Loading state

  // State array to track the disabled status for each input field
  const [disabledInputs, setDisabledInputs] = useState([true, true]);

  // Initial state for input values
  const [inputValues, setInputValues] = useState({
    name: "",
    email: "",
    date_joined: "",
    last_address: "",
    last_is_verified: "Unverified",
  });

  // Fetch user data from API
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(URLs.USER_INFO_URL);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setInputValues({
          name: data.name || "",
          email: data.email || "",
          date_joined: data.date_joined || "",
          last_address: data.last_address || "",
          last_is_verified: data.last_is_verified ? "Verified" : "Unverified",
        });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []); // Empty dependency array means this effect runs once on mount

  // State to track the button text for each input field ("Change", "Save", or "Saved")
  const [buttonTexts, setButtonTexts] = useState(["Change", "Change"]);

  const handleButtonClickUserDelete = () => {
    setUserDelete(true);
  };

  const handleButtonClickChangePassword = () => {
    setChangePassword(true);
  };
  if (isLoading) {
    return (
      <div className="rb-display-flex rb-justify-content-center">
        Loading...
      </div>
    ); // Show a loading message while data is being fetched
  }

  return (
    <div className="user-profile-tabs-content-item user-info">
      <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
        <label htmlFor="display-name">Display Name:</label>
        <input
          id="display-name"
          className={`rb-input ${disabledInputs[0] ? "disabled" : ""}`}
          value={inputValues["name"]}
          disabled={disabledInputs[0]}
          onChange={(e) =>
            handleInputChangeUserProfile("name", e, setInputValues)
          }
        />
        {!hideInProgress ? (
          <button
            onClick={() =>
              handleButtonClickUserProfile(
                0,
                buttonTexts,
                setDisabledInputs,
                setButtonTexts,
                inputValues["name"],
                "name",
              )
            }
          >
            {buttonTexts[0]}
          </button>
        ) : (
          <button></button>
        )}
      </div>

      <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
        <label htmlFor="email-address">E-mail address:</label>
        <input
          id="email-address"
          className="rb-input disabled"
          disabled="disabled"
          value={inputValues["email"]}
        />
        <button></button>
      </div>

      <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
        <label htmlFor="current-user-address">Current user address:</label>
        <input
          id="current-user-address"
          className="rb-input disabled"
          value={inputValues["last_address"]}
          disabled="disabled"
        />
        <button
          onClick={() =>
            handleButtonClickUserAddressHistory(setUserAddressHistoryData)
          }
        >
          User address history
        </button>
      </div>

      <div className="user-profile-line rb-display-flex rb-justify-content-center rb-align-items-center">
        <label htmlFor="identity-vVerification-status">
          Identity Verification Status:
        </label>
        <input
          id="identity-vVerification-status"
          className="rb-input disabled"
          value={inputValues["last_is_verified"]}
          disabled="disabled"
        />
        {!hideInProgress ? <button>Get verified</button> : <button></button>}
      </div>

      <div className="user-profile-line user-profile-line-date rb-display-flex rb-justify-content-center rb-align-items-center">
        <label>Account created on date: </label>
        <span>{inputValues["date_joined"]}</span>
        <button></button>
      </div>
      {!hideInProgress && (
        <>
          <div className="user-profile-line user-profile-line-password rb-display-flex rb-justify-content-center rb-align-items-center">
            <label></label>
            <button onClick={() => handleButtonClickChangePassword()}>
              Change password
            </button>
          </div>

          <div className="user-profile-line user-profile-line-cancel rb-display-flex rb-justify-content-center rb-align-items-center">
            <label></label>
            <button onClick={() => handleButtonClickUserDelete()}>
              Cancel account
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default UserInfo;
