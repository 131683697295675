import React, {useRef, useState, useEffect} from 'react';
import FooterFormArrowSvg from "../svg/FooterFormArrowSvg";
import SocialGithubSvg from "../svg/SocialGithubSvg";
import SocialLinkedinSvg from "../svg/SocialLinkedinSvg";
import SocialXSvg from "../svg/SocialXSvg";
import SocialDiscordSvg from "../svg/SocialDiscordSvg";
import QuotaLimit from "./quota/QuotaLimit";

const Footer = ({staticBasePath}) => {

  const [email, setEmail] = useState('');

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setError('Email field cannot be empty.');
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    setError(null);

    const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;

    const formData = {email};

    try {
      const response = await fetch('/contact/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Something went wrong');
      }

      console.log('Success:', data);
      setSuccess('Thank You!');
      setEmail('');
    } catch (error) {
      console.error('Error:', error);

    }
  };

  // Create a ref for the ul element
  const ulRef = useRef(null);

  // State to store the total height of all li elements
  const [totalHeight, setTotalHeight] = useState(0);

  useEffect(() => {
    if (ulRef.current) {
      // Get all li elements inside the ul
      const liElements = ulRef.current.querySelectorAll('li');

      // Calculate the total height of all li elements
      const height = Array.from(liElements).reduce((sum, li) => sum + li.offsetHeight + 8, 0);

      // Update the state with the total height
      setTotalHeight(height);
    }
  }, [ulRef.current]); // Dependency array to run effect on mount

  // Handler for mouse over event
  const handleMouseOver = () => {
    if (ulRef.current) {
      ulRef.current.style.height = `${totalHeight}px`;
    }
  };

  // Handler for mouse leave event
  const handleMouseLeave = () => {
    if (ulRef.current) {
      ulRef.current.style.height = '0'; // Reset to auto when not hovered
    }
  };
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer-top rb-display-flex rb-justify-content-space-between rb-flex-wrap-wrap">
          <div className="footer-top-left">
            <div className="footer-top-left-title">Sign up to our mailing list</div>
            <div className="wpcf7 no-js" id="wpcf7-f84-o2" lang="en-US" dir="ltr">
              <div className="screen-reader-response">
                <p role="status" aria-live="polite" aria-atomic="true"/>
                <ul/>
              </div>
              <form onSubmit={handleSubmit}>
                <span className="wpcf7-form-control-wrap" data-name="your-email">
              <input
                size={40}
                maxLength={80}
                className="footer-top-left-input"
                autoComplete="email"
                aria-required="true"
                aria-invalid="false"
                placeholder="Enter your email"
                value={email}
                onChange={handleChange}
                type="email"
                name="email"
              />
                  {error && <span className="footer-top-left-input-error">{error}</span>}
                  {success && <span className="footer-top-left-input-success">{success}</span>}

            </span>
                <button
                  className="button rb-display-flex rb-align-items-center footer-top-left-submit"
                  type="submit"
                >
                  <FooterFormArrowSvg/>
                </button>
              </form>
            </div>
          </div>
          <div className="footer-top-center">
            <div className="footer-top-center-title">Products</div>
            <div
              className="footer-top-center-products"
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
            >Choose Product
              <ul ref={ulRef}>
                <li>
                  <a target="_blank" href="https://www.vbase.com/validitybase-for-alternative-data/">validityBase for
                    Alternative Data</a>
                </li>
                <li>
                  <a target="_blank" href="https://www.vbase.com/paper-trading-track-record/">validityBase for Paper
                    Trading</a>
                </li>
                <li>
                  <a target="_blank" href="https://www.vbase.com/quant-research/">validityBase for Quant Research </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.vbase.com/vbase-track-records/">validityBase for Track
                    Records </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.vbase.com/vbase-blockchain-notary/">The World's Simplest
                    Blockchain Notary </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-top-right">
            <ul className="footer-top-right-menu rb-display-flex rb-flex-wrap-wrap">
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1028">
                <a href="https://www.vbase.com/about/">About</a>
              </li>
              <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-774">
                <a target="_blank" rel="noopener" href="https://docs.vbase.com/">
                  Docs
                </a>
              </li>
              <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-661">
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://github.com/validityBase/"
                >
                  Code
                </a>
              </li>
              <li className="menu-item-blog menu-item menu-item-type-post_type menu-item-object-page menu-item-658">
                <a href="https://www.vbase.com/blog/">Blog</a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1027">
                <a href="https://www.vbase.com/pricing/">Pricing</a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1029">
                <a href="https://www.vbase.com/contact/">Contact</a>
              </li>
            </ul>
            {" "}
          </div>
        </div>
        <div
          className="footer-center rb-display-flex rb-justify-content-space-between rb-align-items-center rb-flex-wrap-wrap">
          <div className="footer-center-left rb-display-flex">
            <a href="https://www.vbase.com" aria-label="vBase">
              <img
                src="https://www.vbase.com/wp-content/uploads/2024/05/vb_logo-white-1.svg"
                width={77}
                alt=""
              />
            </a>
            <div className="footer-center-left-social">
              <a
                target="_blank"
                href="https://github.com/validitybase"
                aria-label="github"
              >
          <span>
            <SocialGithubSvg/>
            <span></span>
          </span>
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/validitybase/"
                aria-label="linkedin"
              >
          <span>
           <SocialLinkedinSvg/>
           <span></span>
          </span>
              </a>
              <a
                target="_blank"
                href="https://x.com/validitybase"
                aria-label="twitter"
              >
          <span>
               <SocialXSvg/>
           <span></span>
          </span>
              </a>
              <a
                target="_blank"
                href="https://discord.gg/qjQcCNEBAh"
                aria-label="discord"
              >
          <span>
               <SocialDiscordSvg/>
           <span></span>
          </span>
              </a>
            </div>
          </div>
          <div className="footer-center-right">
            <ul>
              <li>
                <a href="https://www.vbase.com/terms-of-service/">
                  Terms of Service
                </a>
              </li>
              <li>
                <a href="https://www.vbase.com/privacy-policy/">Privacy Policy</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom rb-display-flex rb-justify-content-flex-end">
          <div className="footer-bottom-right">
            © 2024 validityBase. All rights reserved.{" "}
          </div>
        </div>
      </div>
      <QuotaLimit staticBasePath={staticBasePath}/>
    </footer>

  );
};
export default Footer;
