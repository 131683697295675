import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ReportContent from "../components/reports/ReportContent";

const ReportsPage = ({ staticBasePath, djangoContext }) => {
  return (
    <>
      <Header staticBasePath={staticBasePath} djangoContext={djangoContext} />
      <div className="rb-main-content reports-page">
        <div className="rb-container">
          <ReportContent staticBasePath={staticBasePath} />
        </div>
      </div>
      <Footer staticBasePath={staticBasePath} />
    </>
  );
};
export default ReportsPage;
