import React from 'react';

const Tabs = ({activeTabUserProfile, switchTabUserProfile}) => {

  return (
    <div className="user-profile-tabs-buttons">
      <div className="rb-container">
        <div className="user-profile-tabs-buttons-block rb-display-flex rb-justify-content-center">
          <div className="user-profile-tabs-buttons-block-wrap">
            <button
              className={activeTabUserProfile === "#user-info" ? "active" : ""}
              onClick={() => switchTabUserProfile("#user-info")}
            >
              User Info
            </button>
            <button
              className={activeTabUserProfile === "#billing" ? "active" : ""}
              onClick={() => switchTabUserProfile("#billing")}
            >
              Billing
            </button>
            <button
              className={activeTabUserProfile === "#account-settings" ? "active" : ""}
              onClick={() => switchTabUserProfile("#account-settings")}
            >
              Account Settings
            </button>

            <button
              className={activeTabUserProfile === "#usage-statistics" ? "active" : ""}
              onClick={() => switchTabUserProfile("#usage-statistics")}
            >
              Usage Statistics
            </button>


          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
