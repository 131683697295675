import React, {useState, useEffect} from "react";
import Cookies from "js-cookie";
import { fetchQuotaData } from '../../utils/quotaData';
const QuotaLimit = ({staticBasePath}) => {
  // State to control whether the quota limit banner should be shown
  const [quotaShow, setQuotaShow] = useState(false);

  // Function to handle closing the quota limit banner
  const handleClickClose = () => {
    setQuotaShow(false);
    // Set a cookie to remember that the user has closed the banner
    Cookies.set('quotaHide', JSON.stringify(true));
  };

  const [quotaUsageInfo, setQuotaUsageInfo] = useState(0); // State for quota usage info

  useEffect(() => {
    const fetchQuota = async () => {
      try {
        const { quotaUsageInfo, quotaHide } = await fetchQuotaData(); // Use the utility function to get data
        setQuotaUsageInfo(quotaUsageInfo);
        // Show the banner if usage exceeds 90%, not on the payment page, and the banner wasn't hidden
        if (quotaUsageInfo >= 90 && window.location.pathname !== '/profile/payment' && quotaHide !== "true") {
          setQuotaShow(true);
        }
      } catch (error) {
        console.log(error); // Handle any errors
      }
    };

    fetchQuota(); // Call the function to fetch quota data
  }, []);

  return (
    <div className={`quota-limit ${quotaShow ? 'active' : ''}`}>
      {/* Close button for the quota banner */}
      <img
        onClick={() =>
          handleClickClose()
        }
        className="quota-limit-close"
        src={`${staticBasePath}public/images/svg/rb_close.svg`}
        alt="Close"
      />
      {/* Message to inform the user about low quota */}
      You've used {quotaUsageInfo}% of your stamp quota. Consider upgrading your plan <a href="/profile/payment">plan</a>
    </div>
  );
};

export default QuotaLimit;
