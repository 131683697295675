import React from "react";
import Help from "../Help";

const ReportBlock = ({
  Background,
  Color,
  Text,
  Number,
  position,
  text,
  link,
}) => {
  return (
    <div
      className="reports-block"
      style={{ backgroundColor: Background, color: Color }}
    >
      <b>{Text}</b>
      <span className="reports-block-number">{Number}</span>
      <Help position={position} text={text} link={link} />
    </div>
  );
};

export default ReportBlock;
