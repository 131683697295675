import React, {useEffect, useState} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import UserInfo from "../components/user-profile/UserInfo";
import Tabs from "../components/user-profile/Tabs";
import UserAddressHistory from "../components/user-profile/UserAddressHistory";
import UserDelete from "../components/user-profile/UserDelete";
import UserChangePassword from "../components/user-profile/UserChangePassword";
import Billing from "../components/user-profile/Billing";
import AccountSettings from "../components/user-profile/AccountSettings";
import UsageStatistics from "../components/user-profile/UsageStatistics";

const TabContentUserProfile = ({activeTabUserProfile, setUserAddressHistoryData, setUserDelete, setChangePassword}) => {

  switch (activeTabUserProfile) {
    case "#user-info":
      return <UserInfo setUserAddressHistoryData={setUserAddressHistoryData} setUserDelete={setUserDelete} setChangePassword={setChangePassword}
      />;
    case "#billing":
      return <Billing/>;
    case "#account-settings":
      return <AccountSettings />;
    case "#usage-statistics":
      return <UsageStatistics />;
    default:
      return <UserInfo setUserAddressHistoryData={setUserAddressHistoryData} setUserDelete={setUserDelete} setChangePassword={setChangePassword}
      />;
  }
};
const UserProfile = ({staticBasePath, djangoContext}) => {
  const [activeTabUserProfile, setActiveTabUserProfile] = useState(window.location.hash || "#user-info");

  const [userAddressHistoryData, setUserAddressHistoryData] = useState("");

  const [userDelete, setUserDelete] = useState(false);

  const [changePassword, setChangePassword] = useState(false);


  // Update active tab based on URL hash change
  useEffect(() => {
    const handleHashChangeUserProfile = () => {
      setActiveTabUserProfile(window.location.hash || "#user-info");
    };

    window.addEventListener("hashchange", handleHashChangeUserProfile);

    return () => {
      window.removeEventListener("hashchange", handleHashChangeUserProfile);
    };
  }, []);

  // Function to switch tab and update URL hash
  const switchTabUserProfile = (tabId) => {
    window.location.hash = tabId;
    setActiveTabUserProfile(tabId);
  };
  return (
    <>
      <Header staticBasePath={staticBasePath} djangoContext={djangoContext}/>
      <div className="rb-main-content user-profile">
        <div className="rb-container">
          <div className="user-profile-header">

            <h1 className="rb-header rb-header-stamp rb-text-center">User Profile</h1>
          </div>
        </div>
        <Tabs activeTabUserProfile={activeTabUserProfile} switchTabUserProfile={switchTabUserProfile}/>
        <div className="user-profile-tabs-content">
          <div className="rb-container">
            <TabContentUserProfile activeTabUserProfile={activeTabUserProfile}
                                   setUserAddressHistoryData={setUserAddressHistoryData} setUserDelete={setUserDelete} setChangePassword={setChangePassword}/>
          </div>
        </div>
      </div>

      <Footer  staticBasePath={staticBasePath}/>

      {userAddressHistoryData ? (
        <UserAddressHistory staticBasePath={staticBasePath} userAddressHistoryData={userAddressHistoryData}
                            setUserAddressHistoryData={setUserAddressHistoryData}/>
      ) : ""}

      {userDelete ? (
        <UserDelete staticBasePath={staticBasePath} setUserDelete={setUserDelete}/>
      ) : false}


      {changePassword ? (
        <UserChangePassword staticBasePath={staticBasePath} setChangePassword={setChangePassword}/>
      ) : false}
    </>
  )
    ;
};

export default UserProfile;
