import React from "react";
import { useEffect, useRef, useState } from "react";
import QuotaLimitHeader from "./quota/QuotaLimitHeader";
import { URLs } from "../utils/apis";

const Header = ({ staticBasePath, djangoContext, createdHeader }) => {
  const [isAuthenticated, setIsAuthenticated] = useState("null");
  const [email, setEmail] = useState("");
  const [shortName, setShortName] = useState("");

  // for staging
  const [userProfileShow] = useState(true);

  useEffect(() => {
    const { userIsAuthenticated, userEmail, userShortName } = djangoContext;

    setIsAuthenticated(userIsAuthenticated || false);
    setEmail(userEmail || "");
    setShortName(userShortName || "");
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpenDesktop, setIsMenuOpenDesktop] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMobile, setIsOpenMobile] = useState(false);

  const toggleMobileMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsOpen(false);
    setIsOpenMobile(false);
    setIsMenuOpenDesktop(false);
  };

  const toggleDesktopMenu = () => {
    setIsMenuOpen(false);
    setIsOpen(false);
    setIsOpenMobile(false);
    setIsMenuOpenDesktop(!isMenuOpenDesktop);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsMenuOpen(false);
    setIsOpenMobile(false);
    setIsMenuOpenDesktop(false);
  };

  const toggleDropdownMobile = () => {
    setIsOpenMobile(!isOpenMobile);
    setIsMenuOpen(false);
    setIsOpen(false);
    setIsMenuOpenDesktop(false);
  };

  return (
    <div className="rb-top-menu-wrap">
      <nav className="rb-top-menu">
        <div className="rb-logo">
          <a href="/" rel="noopener noreferrer">
            <img
              className="header-logo"
              src={`${staticBasePath}public/images/svg/vb_logo.svg`}
              alt="validityBase"
            />
          </a>
        </div>
        <div className="rb-menu-desktop">
          <div className={`links ${isMenuOpenDesktop ? "open" : ""}`}>
            <a href="/stamp/" className="nav-link">
              Stamp
            </a>
            <a href="/verify/" className="nav-link">
              Verify
            </a>
            <a href="#" className="dropbtn" onClick={toggleDesktopMenu}>
              <span className="arrow">
                {isMenuOpenDesktop ? "\u25B2" : "\u25BC"}
              </span>
            </a>

            <div className="rb-menu-desktop__items">
              <a href="https://vbase.com/">Back to vbase.com</a>
              <a href="https://github.com/validityBase/">Code</a>
              <a href="https://docs.vbase.com/">Docs</a>
            </div>
          </div>

          <div>
            {isAuthenticated ? (
              <div className="rb-user-card">
                {userProfileShow && (
                  <>
                    {window.location.pathname !== URLs.PROFILE_PAYMENT_URL &&
                      window.location.pathname !== URLs.VERIFY_URL && (
                        <QuotaLimitHeader
                          setIsOpen={setIsOpen}
                          createdHeader={createdHeader}
                        />
                      )}
                    <a
                      href={URLs.PROFILE_PAYMENT_URL}
                      className="rb-btn rb-btn-white rb-btn-header-plans"
                    >
                      View Plans
                    </a>
                  </>
                )}
                <div className="rb-user-data" onClick={toggleDropdown}>
                  <p className="name">{shortName}</p>
                </div>
                <div>
                  <ul className="nav-list">
                    <li className="nav-item dropdown" onClick={toggleDropdown}>
                      <a href="#" className="dropbtn">
                        <span className="arrow">
                          {isOpen ? "\u25B2" : "\u25BC"}
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                {isOpen && (
                  <div className="dropdown-content">
                    <a href="/profile/new">User Profile</a>

                    <a
                      href="https://docs.vbase.com/getting-started/web-tools/how-to-use-vbase-stamper"
                      target="_blank"
                    >
                      How to use vBase Stamper
                    </a>
                    <a href="/accounts/logout/" className="logout-btn">
                      Log Out
                    </a>
                  </div>
                )}
              </div>
            ) : (
              <div className="rb-not-authentication-buttons rb-display-flex rb-justify-content-space-between">
                <a href="/accounts/signup/" className="rb-btn rb-btn-black">
                  Create an Account
                </a>

                <a href="/accounts/login/" className="rb-btn rb-btn-white">
                  Sign In
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="rb-menu-mobile">
          <button
            id="mobile-menu-button"
            className={`rb-menu-mobile__toggle ${isMenuOpen ? "open" : ""}`}
            onClick={toggleMobileMenu}
          >
            <img
              id="mobile-menu-open"
              className={` ${isMenuOpen ? "rb-hidden" : ""}`}
              src={`${staticBasePath}public/images/svg/rb_hamburger.svg`}
              alt="open menu"
            />
            <img
              id="mobile-menu-close"
              className={` ${isMenuOpen ? "" : "rb-hidden"}`}
              src={`${staticBasePath}public/images/svg/rb_close.svg`}
              alt="close menu"
            />
          </button>
          {isAuthenticated ? (
            <>
              <a
                href={URLs.PROFILE_PAYMENT_URL}
                className="rb-btn rb-btn-white rb-btn-header-plans"
              >
                View Plans
              </a>
              <div className="rb-user-card" onClick={toggleDropdownMobile}>
                <p className="name">{shortName}</p>
                <div>
                  <ul className="nav-list">
                    <li className="nav-item dropdown">
                      <a href="#" className="dropbtn-mobile">
                        <span className="arrow-mobile">
                          {isOpenMobile ? "\u25B2" : "\u25BC"}
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                {isOpenMobile && (
                  <div className="dropdown-content-mobile">
                    <a href="/profile/new">User Profile</a>
                    <a
                      href="https://docs.vbase.com/getting-started/web-tools/how-to-use-vbase-stamper"
                      target="_blank"
                    >
                      How to use vBase Stamper
                    </a>
                    <a href="/accounts/logout/" className="logout-btn">
                      Log Out
                    </a>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="rb-not-authentication-buttons rb-display-flex rb-justify-content-space-between">
              <a href="/accounts/signup/" className="rb-btn rb-btn-black">
                Create an Account
              </a>

              <a href="/accounts/login/" className="rb-btn rb-btn-white">
                Sign In
              </a>
            </div>
          )}
          <div className="rb-menu-mobile__items">
            <a href="/stamp/">Stamp</a>
            <a href="/verify/">Verify</a>
            <a href="https://vbase.com/">Back to vbase.com</a>
            <a href="https://github.com/validityBase/">Code</a>
            <a href="https://docs.vbase.com/">Docs</a>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
