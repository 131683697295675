import React, { useState, useEffect } from "react";
import Pagination from "./user-profile/Pagination";
import LendingPricingSvg from "../svg/LendingPricingSvg";
import { userFoundData } from "../utils/apis";
const UserFoundContent = () => {
  // Initial state for input values
  const [inputValues, setInputValues] = useState({
    stamp_history: [],
    current_page: 1,
    total_pages: 1,
    persistent_ID: "",
    current_display_name: "",
    current_user_ID: "",
    current_account_status: "Not Verified",
    total_stamps: "",
    sets: "",
    download_csv: "",
  });

  useEffect(() => {
    const regex = /^(0x)?[0-9a-fA-F]{64}$/;
    const params = new URLSearchParams(window.location.search);
    const uidParam = params.get("uid");
    const checkValid = regex.test(uidParam) || uidParam === "";
    alert(checkValid);
    if (checkValid) {
      userFoundData(1, setInputValues, uidParam);
    }
  }, []);

  // Handle pagination
  const handlePageChange = (newPage) => {
    userFoundData(newPage, setInputValues, uidParam); // Call the function with the new page number
  };

  return (
    <div className="reports reports-producer user-found">
      <div className="reports-text">
        <h1>User Found!</h1>
      </div>
      <div className="reports-content">
        <div className="reports-head rb-display-flex rb-justify-content-space-between rb-flex-wrap-wrap">
          <div className="reports-head-left">
            <div className="reports-head-left-block">
              <span>Persistent ID:</span>
              <b>{inputValues.persistent_ID}</b>
            </div>
            <div className="reports-head-left-block">
              <span>Current Display Name:</span>
              <b>{inputValues.current_display_name}</b>
            </div>
            <div className="reports-head-left-block">
              <span>Current User ID:</span>
              <b>{inputValues.current_user_ID}</b>
            </div>
            <div className="reports-head-left-block">
              <span>Current Account Status:</span>
              <b>{inputValues.current_account_status}</b>
            </div>
          </div>
          <div className="reports-head-right">
            <div className="reports-blocks rb-display-flex rb-justify-content-space-between">
              {inputValues?.total_stamps && (
                <ReportBlock
                  Background="#EDF0E5"
                  Color="#000000"
                  Text="Total Stamps"
                  Number={inputValues?.total_stamps}
                  position="top-right"
                  text="Total Stamps Total Stamps Total Stamps Total Stamps"
                  link="#"
                />
              )}
              {inputValues?.sets && (
                <ReportBlock
                  Background="#1B2F28"
                  Color="#FFFFFF"
                  Text="Sets"
                  Number={inputValues?.sets}
                  position="top-right"
                  text="Sets Sets Sets Sets Sets Sets Sets Sets"
                  link="#"
                />
              )}
            </div>
          </div>
        </div>
        <div className="html-table-wrap">
          <div className="reports-table-head rb-display-flex rb-justify-content-space-between">
            <div className="reports-table-head-left">Stamp History</div>
          </div>
          {inputValues["stamp_history"].length > 0 ? (
            <div className="html-table">
              <table>
                <thead>
                  <tr>
                    <th>Content ID</th>
                    <th>Timestamp</th>
                    <th>Receipt Link</th>
                    <th>Set ID/Name</th>
                  </tr>
                </thead>
                <tbody>
                  {inputValues["stamp_history"].map(
                    (stamp_history_item, index) => (
                      <tr key={index}>
                        <td>{stamp_history_item.content_ID}</td>
                        <td>{stamp_history_item.timestamp}</td>
                        <td>
                          {stamp_history_item.receipt_link_vbase}
                          {stamp_history_item.receipt_link_pdf}
                          {stamp_history_item.receipt_link_blockchain}
                        </td>
                        <td>{stamp_history_item.set_ID_name}</td>
                      </tr>
                    ),
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No data available.</p>
          )}
        </div>
        {/* Pagination Controls */}
        {inputValues["total_pages"] > 1 && (
          <Pagination
            currentPage={inputValues["current_page"]}
            totalPages={inputValues["total_pages"]}
            onPageChange={handlePageChange}
          />
        )}
      </div>
      <div className="reports-buttons rb-display-flex rb-align-items-center">
        <a
          href="/reports/"
          className="button rb-btn button-dark rb-display-flex rb-justify-content-center rb-align-items-center"
        >
          <LendingPricingSvg />
          Back
        </a>
      </div>
    </div>
  );
};

export default UserFoundContent;
