import { URLs } from "../utils/apis";
export const handleButtonClickUserProfile = async (
  index,
  buttonTexts,
  setDisabledInputs,
  setButtonTexts,
  inputValue,
  field,
) => {
  if (buttonTexts[index] === "Change") {
    // Enable input and change button text to "Save"
    setDisabledInputs((prevState) => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
    setButtonTexts((prevState) => {
      const newState = [...prevState];
      newState[index] = "Save";
      return newState;
    });
  } else if (buttonTexts[index] === "Save") {
    setDisabledInputs((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
    // Send data via fetch and after receiving response, change button text to "Saved"
    const csrfToken = document.querySelector(
      "[name=csrfmiddlewaretoken]",
    ).value;
    try {
      const response = await fetch(URLs.USER_INFO_SAVE_PROFILE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify({ [field]: inputValue }), // Send the specific input value
      });

      if (response.ok) {
        // After a successful response, set button text to "Saved"
        setButtonTexts((prevState) => {
          const newState = [...prevState];
          newState[index] = "Saved!";
          return newState;
        });

        // Change the text back to "Change" after 2 seconds
        setTimeout(() => {
          setButtonTexts((prevState) => {
            const newState = [...prevState];
            newState[index] = "Change";
            return newState;
          });
        }, 2000);
      } else {
        console.error("Failed to save data");
      }
    } catch (error) {
      console.error("Error while saving data", error);
    }
  }
};

export const handleInputChangeUserProfile = (field, event, setInputValues) => {
  setInputValues((prevValues) => ({
    ...prevValues,
    [field]: event.target.value,
  }));
};
export const handleButtonClickUserAddressHistory = (
  setUserAddressHistoryData,
) => {
  const fetchUserData = async () => {
    try {
      const response = await fetch(URLs.USER_INFO_USER_ADDRESS_HISTORY_URL);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setUserAddressHistoryData(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  fetchUserData();
};

export const handleButtonClickDeleteUser = async () => {
  const csrfToken = document.querySelector("[name=csrfmiddlewaretoken]").value;

  try {
    await fetch(URLs.USER_PROFILE_DELETE_ACCOUNT_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to delete account");
        }
      })
      .then((data) => {
        alert(data.message);
        window.location.href = "/";
      })
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error("Error delete user", error);
  }
};
